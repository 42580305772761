import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Layout from '../../components/common/layout/layout';
import SEO from '../../components/common/SEO/SEO';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};

const MDXLayout = ({
  children
}) => <Layout>
        <SEO title=" Política de privacidad " description="Política de privacidad de AlmaySer Terapias Florales" robots="noindex nofollow" />
        <div className="docs">
            {children}
        </div>
    </Layout>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h3><strong parentName="h3">{`POLÍTICA DE PRIVACIDAD`}</strong></h3>
    <p><strong parentName="p">{`1.`}</strong>{` `}<strong parentName="p">{`INFORMACIÓN AL USUARIO`}</strong></p>
    <p><strong parentName="p">{`AlmaySer Terapias Florales.`}</strong>{`, como Responsable del Tratamiento, le informa que, según lo dispuesto en el Reglamento (UE) 2016/679, de 27 de abril, (RGPD) y en la L.O. 3/2018, de 5 de diciembre, de protección de datos y garantía de los derechos digitales (LOPDGDD), trataremos su datos tal y como reflejamos en la presente Política de Privacidad.`}</p>
    <p>{`En esta Política de Privacidad describimos cómo recogemos sus datos personales y por qué los recogemos, qué hacemos con ellos, con quién los compartimos, cómo los protegemos y sus opciones en cuanto al tratamiento de sus datos personales.`}</p>
    <p>{`Esta Política se aplica al tratamiento de sus datos personales recogidos por la empresa para la prestación de sus servicios. Si acepta las medidas de esta Política, acepta que tratemos sus datos personales como se define en esta Política.`}</p>
    <p><strong parentName="p">{`2. CONTACTO`}</strong></p>
    <p>{`Denominación social: `}<strong parentName="p">{`AlmaySer Terapias Florales`}</strong></p>
    <p>{`Nombre comercial: `}<strong parentName="p">{`AlmaySer Terapias Florales`}</strong></p>
    <p>{`CIF: `}<strong parentName="p">{`48089859x`}</strong></p>
    <p>{`Domicilio: `}<strong parentName="p">{`Gran de Sant andreu, Barcelona, 08030, Barcelona, España`}</strong></p>
    <p>{`e-mail: `}<strong parentName="p"><a parentName="strong" {...{
          "href": "mailto:info@almayser.es"
        }}>{`info@almayser.es`}</a></strong></p>
    <p><strong parentName="p">{`3. PRINCIPIOS CLAVE`}</strong></p>
    <p>{`Siempre hemos estado comprometidos con prestar nuestros servicios con el más alto grado de calidad, lo que incluye tratar sus datos con seguridad y transparencia. Nuestros principios son:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Legalidad`}</strong>{`: Sólo recopilaremos sus datos personales para fines específicos, explícitos y legítimos.`}</li>
      <li parentName="ul"><strong parentName="li">{`Minimización de datos`}</strong>{`: Limitamos la recogida de datos de carácter personal a lo que es estrictamente relevante y necesario para los fines para los que se han recopilado.`}</li>
      <li parentName="ul"><strong parentName="li">{`Limitación de la Finalidad`}</strong>{`: Sólo recogeremos sus datos personales para los fines declarados y solo según sus deseos.`}</li>
      <li parentName="ul"><strong parentName="li">{`Precisión`}</strong>{`: Mantendremos sus datos personales exactos y actualizados.`}</li>
      <li parentName="ul"><strong parentName="li">{`Seguridad de los Datos`}</strong>{`: Aplicamos las medidas técnicas y organizativas adecuadas y proporcionales a los riesgos para garantizar que sus datos no sufran daños, tales como divulgación o acceso no autorizado, la destrucción accidental o ilícita o su pérdida accidental o alteración y cualquier otra forma de tratamiento ilícito.`}</li>
      <li parentName="ul"><strong parentName="li">{`Acceso y Rectificación`}</strong>{`: Disponemos de medios para que acceda o rectifique sus datos cuando lo considere oportuno.`}</li>
      <li parentName="ul"><strong parentName="li">{`Conservación`}</strong>{`: Conservamos sus datos personales de manera legal y apropiada y solo mientras es necesario para los fines para los que se han recopilado.`}</li>
      <li parentName="ul"><strong parentName="li">{`Las transferencias internacionales`}</strong>{`: cuando se dé el caso de que sus datos vayan a ser transferidos fuera de la UE/EEE se protegerán adecuadamente.`}</li>
      <li parentName="ul"><strong parentName="li">{`Terceros`}</strong>{`: El acceso y transferencia de datos personales a terceros se llevan a cabo de acuerdo con las leyes y reglamentos aplicables y con las garantías contractuales adecuadas.`}</li>
      <li parentName="ul"><strong parentName="li">{`Marketing Directo y cookies`}</strong>{`: Cumplimos con la legislación aplicable en materia de publicidad y cookies.`}</li>
    </ul>
    <p><strong parentName="p">{`4. RECOGIDA Y TRATAMIENTO DE SUS DATOS PERSONALES`}</strong></p>
    <p>{`Las tipos de datos que se pueden solicitar y tratar son:`}</p>
    <ul>
      <li parentName="ul">{`Datos de carácter identificativo.`}</li>
    </ul>
    <p>{`También recogemos de forma automática datos sobre su visita a nuestro sitio web  según se describe en la política de cookies.`}</p>
    <p>{`Siempre que solicitemos sus Datos personales, le informaremos con claridad de qué datos personales recogemos y con qué fin. En general, recogemos y tratamos sus datos personales con el propósito de:`}</p>
    <ul>
      <li parentName="ul">{`Proporcionar información, servicios, productos, información relevante y novedades en el sector.`}</li>
      <li parentName="ul">{`Envío de comunicaciones.`}</li>
    </ul>
    <p><strong parentName="p">{`5. LEGITIMIDAD`}</strong></p>
    <p>{`De acuerdo con la normativa de protección de datos aplicable, sus datos personales podrán tratarse siempre que:`}</p>
    <ul>
      <li parentName="ul">{`Nos ha dado su consentimiento a los efectos del tratamiento. Por supuesto podrá retirar su consentimiento en cualquier momento.`}</li>
      <li parentName="ul">{`Por requerimiento legal.`}</li>
      <li parentName="ul">{`Por existir un interés legítimo que no se vea menoscabado por sus derechos de privacidad, como por ejemplo el envío de información comercial bien por suscripción a nuestra newsletter o por su condición de cliente.`}</li>
      <li parentName="ul">{`Por ser necesaria para la prestación de alguno de nuestros servicios mediante relación contractual entre usted y nosotros.`}</li>
    </ul>
    <p><strong parentName="p">{`6. COMUNICACIÓN DE DATOS PERSONALES`}</strong></p>
    <p>{`Los datos pueden ser comunicados a empresas relacionadas con `}<strong parentName="p">{`AlmaySer Terapias Florales`}</strong>{` para la prestación de los diversos servicios en calidad de Encargados del Tratamiento. La empresa no realizará ninguna cesión, salvo por obligación legal.`}</p>
    <p><strong parentName="p">{`7. SUS DERECHOS`}</strong></p>
    <p>{`En relación con la recogida y tratamiento de sus datos personales, puede ponerse en contacto con nosotros en cualquier momento para:`}</p>
    <ul>
      <li parentName="ul">{`Acceder a sus datos personales y a cualquier otra información indicada en el Artículo 15.1 del RGPD.`}</li>
      <li parentName="ul">{`Rectificar sus datos personales que sean inexactos o estén incompletos de acuerdo con el Artículo 16 del RGPD.`}</li>
      <li parentName="ul">{`Suprimir sus datos personales de acuerdo con el Artículo 17 del RGPD.`}</li>
      <li parentName="ul">{`Limitar el tratamiento de sus datos personales de acuerdo con el Artículo 18 del RGPD.`}</li>
      <li parentName="ul">{`Solicitar la portabilidad de sus datos de acuerdo con el Artículo 20 del `}<a parentName="li" {...{
          "href": "https://presencialismo.com/"
        }}>{`RGPD`}</a>{`.`}</li>
      <li parentName="ul">{`Oponerse al tratamiento de sus datos personales de acuerdo con el artículo 21 del RGPD.`}</li>
    </ul>
    <p>{`Si ha otorgado su consentimiento para alguna finalidad concreta, tiene derecho a retirar el consentimiento otorgado en cualquier momento, sin que ello afecte a la licitud del tratamiento basado en el consentimiento previo a su retirada `}<a parentName="p" {...{
        "href": "https://presencialismo.com/"
      }}>{`rrhh`}</a>{`.`}</p>
    <p>{`Puede ejercer estos derechos enviando comunicación, motivada y acreditada, a `}<strong parentName="p"><a parentName="strong" {...{
          "href": "mailto:info@almayser.es"
        }}>{`info@almayser.es`}</a>{`.`}</strong></p>
    <p>{`También tiene derecho a presentar una reclamación ante la Autoridad de control competente (`}<a parentName="p" {...{
        "href": "https://aepd.es/"
      }}>{`www.aepd.es`}</a>{`) si considera que el tratamiento no se ajusta a la normativa vigente.`}</p>
    <p><strong parentName="p">{`8. INFORMACIÓN LEGAL`}</strong></p>
    <p>{`Los requisitos de esta Política complementan, y no reemplazan, cualquier otro requisito existente bajo la ley de protección de datos aplicable, que será la que prevalezca en cualquier caso.`}</p>
    <p>{`Esta Política está sujeta a revisiones periódicas y la empresa puede modificarla en cualquier momento. Cuando esto ocurra, le avisaremos de cualquier cambio y le pediremos que vuelva a leer la versión más reciente de nuestra Política y que confirme su aceptación.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      